import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Image from '../../components/Image';

const teamData = [
    {
        image: 'team-01',
        name: 'Sr Janen Sara',
        designation: 'Sr Product Designer',
        location: 'CO Miego, AD, USA',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]

    },
    {
        image: 'team-02',
        name: 'Afsana Nila',
        designation: 'App Developer',
        location: 'Bangladesh',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiLinkedin />,
                url: '#'
            },
        ]

    },
    {
        image: 'team-03',
        name: 'Afanan Sifa',
        designation: 'Accounts Manager',
        location: 'Poland',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]

    },
]
const getIcon = (iconName) => {

    switch (iconName) {
        case 'FiFacebook':
            return <FiFacebook />

        case 'FiTwitter':
            return <FiTwitter />
        case 'FiInstagram':
            return <FiInstagram />
        case 'FiLinkedin':
            return <FiLinkedin />


        default:
            break;
    }
}

const TeamTwo = ({ column, teamStyle, members }) => {
    console.log(members, 'members');
    return (
        <div className="row row--15" style={{
            justifyContent: 'center'
        }}>
            {
                members.map(({ name, position, image, socials, description }, index) => (
                    <div className={`${column}`} key={index}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <div className={`rn-team ${teamStyle}`}>
                                <div className="inner">
                                    <figure className="thumbnail">
                                        <Image src={image} />
                                    </figure>
                                    <figcaption className="content">
                                        <h2 className="title">{name}</h2>
                                        <h6 className="subtitle theme-gradient">{position}</h6>
                                        <p className="description">{description}</p>
                                        <ul className="social-icon social-default icon-naked mt--20" >
                                            {socials.map((social, index) =>
                                                <li key={index}><a href={`${social.url}`}>{getIcon(social.icon)}</a></li>
                                            )}
                                        </ul>
                                    </figcaption>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))
            }
        </div >
    )
}

export default TeamTwo;
