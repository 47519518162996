import PropTypes from "prop-types";
import React from 'react'


const SEO = ({ title }) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || At HDK Finance, our goal is to help our clients realize their full financial potential by providing them with expert advice and competitive choices.</title>
            <meta name="robots" content="follow" />
            <meta name="description" content="At HDK Finance, our goal is to help our clients realize their full financial potential by providing them with expert advice and competitive choices." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


