import React from 'react';
import Typed from 'react-typed';
import { getImage } from 'gatsby-plugin-image';
import FullWidthImage from '../../components/FullWidthImage';
import Image from '../../components/Image';

const AboutOne = ({
    title,
    services,
    description1,
    description2,
    cta,
    image
}) => {

    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <Image className="w-100" src={image} alt={title} />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">{title} <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={services}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>{description1}</p>
                                <p>{description2}</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>{cta.text}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne
