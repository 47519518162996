import PropTypes from "prop-types";
import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { graphql, Link } from "gatsby";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TeamTwo from "../elements/team/TeamTwo";

import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import Layout from "../components/Layout";
import { getImage } from "gatsby-plugin-image";
import TestimonialThree from "../elements/testimonial/TestimonialThree";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  topBanner,
  subheading,
  mainpitch,
  description,
  intro,
  about,
  services,
  actions,
  teams,
  reviews,
}) => {
  console.log(mainpitch.services, "about");
  return (
    <>
      <SEO title={title} />
      <main className="page-wrapper">
        {/* <HeaderTopNews {...topBanner} /> */}

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">{heading}</span>
                  <h1 className="theme-gradient display-two">
                    {mainpitch.title} <br />{" "}
                    <Typed
                      strings={mainpitch.services}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">{mainpitch.description}</p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/contact"
                      rel="noreferrer"
                    >
                      {mainpitch.callToAction}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne {...about} />
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={services.subtitle}
                  title={services.title}
                  description={services.description}
                />
              </div>
            </div>
            <ServiceFive
              serviceStyle="gallery-style"
              textAlign="text-start"
              blocks={services.blocks}
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area">
          <div className="wrapper">
            <CalltoActionFive {...actions} />
          </div>
        </div>
        {/* End Call To Action Area  */}

        {/* Start Team Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={teams.subtitle}
                  title={teams.title}
                  description={teams.description}
                />
              </div>
            </div>
            <TeamTwo
              column="col-lg-4 col-md-6 col-12"
              teamStyle="team-style-default style-two"
              members={teams.members}
            />
          </div>
        </div>
        {/* End Team Area  */}
        {/* <Separator /> */}
        {/* Start Mission Area   */}
        {/* <Mission /> */}
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={reviews.subtitle}
                  title={reviews.title}
                  description={reviews.description}
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" reviews={reviews.reviews} />
          </div>
        </div>
        {/* End Testimonial Area  */}
        {/* 
        <Separator />
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Latests News"
                  title="Our Latest News."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </main>
    </>
  );
};
const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark || {};

  return (
    <Layout>
      <IndexPageTemplate {...(frontmatter || {})} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        topBanner {
          title
          subtitle
          callToAction
          bannerUrl {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        mainpitch {
          title
          description
          callToAction
          services 
        }
        about {
          title
          services
          description1
          description2
          cta {
            text
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        services {
          title
          subtitle
          description
          blocks {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        actions {
          title
          subtitle
          cta {
            text
            url
          }
        }
        teams {
          title
          description
          subtitle
          members {
            name
            position
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            socials {
              url
              icon
            }
          }
        }
        reviews {
          subtitle
          description
          reviews {
            description
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          title
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
